@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #111111;
  color: #ffffff;
}

html {
  scroll-behavior: smooth; /* Add smooth scrolling behavior to the entire page */
}
